.download-files {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.download-files .footer-text{
    width: 100%;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
}

.download-files .tnc{
    font-size: 12px;
}

.download-files .header{
    width: 280px;
    background: var(--primary);
    margin: -1rem;
    margin-bottom: 0rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: white;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    font-weight: 800;
}

.download-files .delete-warn{
    color: var(--error);
    font-weight: 600;
    text-align: center;
    font-size: 12px;
    margin: 5px 0px;
}