:root {
  --background: #d4f4e1;
  --primary: #5049f1;
  --primary-dark: #3527c5;
  --soft: #febbbb;
  --dark: #444555;
  --error: #be924f;
}

* {
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.App {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  /* height: 100%; */
  /* overflow: hidden; */
  background: var(--background);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--dark);
}

.app-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hidden {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.inline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline.sb {
  justify-content: space-between;
}

.small-font {
  font-size: 12px;
}

.full {
  width: 100%;
}

.text-dark {
  color: var(--dark);
}

.ai-base {
  align-items: baseline !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.disable {
  background: #c2c2c2 !important;
  cursor: no-drop !important;
}

.pointer {
  cursor: pointer;
}

.text-primary {
  color: var(--primary);
}

.icon-width-limit {
  max-width: calc(100% - 50px);
  overflow: unset;
}

.zero-margin {
  margin: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

a {
  color: inherit;
}

.news-container {
  width: fit-content;
  margin: auto;
  margin-top: 40px;
  font-size: 14px;
  height: 30px;
  display: flex;
}

.news-title {
  background: var(--primary);
  color: white;
  padding: 2px 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-message {
  padding: 2px 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: white;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.news-container > .small-message {
  padding: 2px 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding-left: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

@media only screen and (max-width: 500px) {
  .news-container > .small-message {
    display: flex;
  }
  .news-container > .news-message {
    display: none;
  }
  .news-container > .news-title {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .app-wrapper {
    flex-direction: column-reverse;
  }
}
