.content-section .title{
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: var(--dark);
}

.content-section .share-btn{
    user-select: none;
    padding: 8px 10px;
    background: var(--primary);
    min-width: 120px;
    text-align: center;
    color: white;
    border-radius: 4px;
    font-weight: 800;
    padding-right: 20px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
}

.content-section .upload-btn:hover{
    background: var(--primary-dark);
}

.content-section .share-btn img{
    height: 18px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
}

.content-section .footer{
    font-size: 14px;
    color: var(--dark);
}

.content-section .file-list{
    width: 100%;
    overflow: auto;
    margin-bottom: 20px;
}

.content-section .terms-n-condition{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 10px;
}

.content-section .upload-btn{
    user-select: none;
    padding: 5px 10px;
    background: var(--primary);
    width: 100%;
    text-align: center;
    color: white;
    border-radius: 4px;
    font-weight: 800;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.content-section .full{
    width: 100%;
}

.upload-stick-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid var(--background);
    padding-bottom: 5px;
    padding: 10px;
}

.upload-stick-wrapper .icon{
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.upload-stick-wrapper .delete-icon{
    height: 20px;
    width: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.content-section .delete-after-wrapper{
    display: flex;
    justify-content: space-between;
}

.content-section .limit-warn{
    margin-top: 10px;
    color: var(--error);
}

.content-section select{
    width: fit-content;
    border: 1px solid #edecf9;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    color: var(--dark);
    background: var(--background);
}

.content-section select:focus{
    outline: none;
}

.content-section select option{
    border-radius: 4px;
    padding:0 30px 0 10px;
    margin: 10px;
}