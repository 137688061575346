.header-section{
    position: relative;
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 2px 20px 7px rgba(0,0,0,.05);
    justify-content: space-between;
    z-index: 1;
}

.logo-title{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: var(--primary);
    cursor: pointer;
}

.header-section .about{
    cursor: pointer;
}

.logo-img{
    height: 28px;
    vertical-align: middle;
    border-radius: 50%;
}