
.waveHorizontals {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    overflow: hidden;
  }
  
  .waveHorizontal {
    width: 200%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 350px 100%;
    transform-origin: 0 100% 0;
  
    /* animation: move linear infinite; */
    animation-name: move;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  
  }
  
  #waveHorizontal1 {
    background-image: url('/wave1.svg');
    animation-delay: -2s;
    animation-duration: 12s;
  }
  
  #waveHorizontal2 {
    background-image: url('/wave2.svg');
    animation-delay: -2s;
    animation-duration: 5s;
  }
  
  #waveHorizontal3 {
    background-image: url('/wave3.svg');
    animation-delay: -1s;
    animation-duration: 3s;
  }
  
  @keyframes move {
    0% {
      transform: translate(-175px, 0px) scale(1, 1);
    }
  
    50% {
      transform: translate(-87px, 0px) scale(1, 0.5);
    }
  
    100% {
      transform: translate(0px, 0px) scale(1, 1);
    }
  }