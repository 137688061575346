.left-content-section{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    flex-direction: column;
    width: 480px;
}

.left-content-section .hello-wrapper{
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--dark);
}

.left-content-section .hello-emoji{
    background: rgba(194, 194, 194, 0.49);
    padding: 0.25rem;
    border-radius: 50px;
    font-size: 1.7rem;
}

.left-content-section .comment{
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark);
    margin-bottom: 10px;
}

.left-content-section .italic{
    font-style: italic;
    color: var(--error);
}

.left-content-section .attribute{
    color: var(--primary);
}

@media only screen and (max-width: 500px){
    .left-content-section {
        width: auto;
    }
}