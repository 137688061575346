/* Form Panel Styles */
.form-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 2rem;
    border-radius: 1.5rem 1.5rem 0 0;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    max-height: 90vh;
    overflow-y: auto;
}

.form-panel.open {
    transform: translateY(0);
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 9999px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    background-color: #f3f4f6;
}

/* Form Styles */
.interest-form {
    max-width: 32rem;
    margin: 0 auto;
}

.form-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1.5rem;
    text-align: center;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    background-color: #f9fafb;
    color: #1f2937;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #9333ea;
    box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.1);
}

.form-group input::-moz-placeholder,
.form-group textarea::-moz-placeholder {
    color: #9ca3af;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: #9ca3af;
}

.submit-button {
    width: 100%;
    background: linear-gradient(to right, #9333ea, #db2777);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-button:hover {
    background: linear-gradient(to right, #7e22ce, #be185d);
}