.main-section {
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
  position: relative;
  gap: 1rem;
}

.content-section {
  background: white;
  padding: 1rem;
  width: 280px;
  min-height: 250px;
  max-height: 95%;
  border-radius: 4px;
  box-shadow: 0 2px 20px 7px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.app-name {
  position: absolute;
  top: 1rem;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0px;
  background: var(--soft);
  padding: 0 20px;
  border-radius: 4px;
  width: 80%;
  right: 34%;
  padding-left: 6rem;
  box-shadow: 0 2px 20px 7px rgba(0, 0, 0, 0.05);
}

.chat-bubble {
  background: var(--primary);
}

.t1 {
  padding: 10px 20px;
  background: #d4f4e1;
  /* box-shadow: 20px 20px 60px #cbcad6, -20px -20px 60px #ffffff; */
  border-radius: 10px;
}

.t2 {
  font-size: 1.5rem;
  font-weight: bold;
}
.t3 {
  font-size: 1rem;
}

@media only screen and (min-width: 500px) {
  .t1 {
    display: none;
  }
  .main-section {
    margin-left: 5rem;
  }
}
